import React from 'react';
import MaterialUILayout from '../components/material-ui-layout';

const js404 = () => {
  return (
    <MaterialUILayout>
      <h1>Error 404: Page not found</h1>
      <div>
        Hmmm... We can't find what you're looking for. Try navigating to another
        page in the menu to the right?
      </div>
    </MaterialUILayout>
  );
};

export default js404;
